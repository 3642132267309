import { getHeader, parapharmacyPreviewUrl } from '@/config/config';
import axios from 'axios';

export const generatePdfPreview = async (templateId, body) => {
  const url = new URL(parapharmacyPreviewUrl(templateId));
  const raw = await axios.post(url.toString(), body, {
    headers: getHeader(),
    responseType: 'blob',
  });
  return window.URL.createObjectURL(raw.data);
};
